// src/components/Hero.js
import React from "react";
import Header from "./Header"; // Import your Header component
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { heroBg, pattern } from "../assets";
import Grow from '@mui/material/Grow';

function Hero() {
  return (
    <Box
      height="80vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      style={{ 
        background: `linear-gradient(170deg, rgba(0,0,0,.4) 0%, rgba(0, 0, 0,.8) 70%), url(${pattern})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "bottom",
      }}
    >
    
        <Header />
        <Container maxWidth="lg">
        <Grow in={true} timeout={1200}>

          <Box px={3}  margin="auto 0" textAlign="center">
            <Typography variant="h3" color="secondary" gutterBottom style={{fontWeight: "900"}}>
            Welcome to our gaming haven, where fun knows no bounds!

            </Typography>
            <Typography variant="h5" color="primary.main50" >
            At Onlinetour Pokies, we're on a mission to make entertainment accessible to everyone. Whether you're a casual player looking to unwind or a hardcore gamer seeking your next challenge, you've arrived at the perfect destination.            </Typography>
          </Box>
          </Grow>

        </Container>
    </Box>
  );
}

export default Hero;
