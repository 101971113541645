import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles'; 
import Grow from '@mui/material/Grow';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    color: "#ffffff"
  },
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    textAlign: 'center',
    color: "#ffffff"
  },
  paper: {
    padding: "2rem 1.5rem",
    borderRadius: '30px!important',
    flex: 1, 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#EDB426!important',
    backgroundColor: '#1a4d2e!important'
  },
}));

// ... (previous code)

export default function Benefits() {
  const classes = useStyles(); // Apply the defined styles

  return (
    <Container maxWidth="lg" className={classes.container} id="about">
      <Typography variant="h4" gutterBottom textAlign="center">
      About Us
      </Typography>
      <Typography variant="h5" gutterBottom textAlign="center">
      Our journey began with a simple belief – that everyone should have access to boundless fun. Onlinetour Pokies was founded on the idea that gaming can bridge gaps, connect people, and bring joy to countless lives. Our mission is clear: to provide a haven where entertainment knows no barriers.
      </Typography>
      <Typography variant="h4" gutterBottom textAlign="center" marginTop="2rem">
      Discover the Onlinetour Pokies Difference:
      </Typography>
      <Grid container justifyContent="center" alignItems="stretch" spacing={3} marginTop="1rem">
        <Grow in={true} timeout={1000} appear={true}>
          <Grid item xs={12} sm={4} className={classes.paperContainer}>
            <Paper className={classes.paper}>
              <Typography variant="h5">
              Variety
              </Typography>
              <Typography variant="body1">
              With a vast selection of games spanning numerous genres, you'll always find something that suits your mood.              </Typography>
            </Paper>
          </Grid>
        </Grow>

        <Grow in={true} timeout={1400} appear={true}>
          <Grid item xs={12} sm={4} className={classes.paperContainer}>
            <Paper className={classes.paper}>
              <Typography variant="h5">
              User-Friendly:
              </Typography>
              <Typography variant="body1">
              Our intuitive interface makes navigation a breeze, ensuring a seamless gaming experience.              </Typography>
            </Paper>
          </Grid>
        </Grow>

        <Grow in={true} timeout={1600} appear={true}>
          <Grid item xs={12} sm={4} className={classes.paperContainer}>
            <Paper className={classes.paper}>
              <Typography variant="h5">
              Free Entertainment
              </Typography>
              <Typography variant="body1">
              We're committed to providing free gaming enjoyment without any hidden costs or subscriptions.              </Typography>
            </Paper>
          </Grid>
        </Grow>

        <Grow in={true} timeout={1700} appear={true}>
          <Grid item sm={12} className={classes.paperContainer}>
            <Paper className={classes.paper}>
              <Typography variant="h5">
              Community
              </Typography>
              <Typography variant="body1">
              Join a passionate community of gamers, share your experiences, and make new friends.              </Typography>
            </Paper>
          </Grid>
        </Grow>

      </Grid>
    </Container>
  );
}
