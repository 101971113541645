import {KingdomFight, SudokuClassic, SnakesLadders, SnakeKing, EnchantedWaters, ZombiesCantJump2, RampageRacer, BowlingStars, CityCricket, BubbleWipeout, EscapeRun, MergeMania} from "../assets";

 const games = [
    {
      id: 1,
      title: 'Kingdom Fight',
      image: KingdomFight,
      iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/SyfxJ3a75Cr',
    },
    {
      id: 2,
      title: 'Sudoku Classic',
      image: SudokuClassic,
      iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/SJgx126Qc0H',
    },
    {
        id: 3,
        title: 'Snakes&Ladders',
        image: SnakesLadders,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/rJWyhp79RS',
      },
      {
        id: 4,
        title: 'Snake King',
        image: SnakeKing,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/ryTknTX5AS',
      },
      {
        id: 5,
        title: 'Enchanted Waters',
        image: EnchantedWaters,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/HJskh679Cr',
      },
      {
        id: 6,
        title: 'Zombies Can`t Jump 2',
        image: ZombiesCantJump2,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/rkxMV8TI6Wg',
      },
      {
        id: 7,
        title: 'Rampage Racer',
        image: RampageRacer,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/p7HOjYF4O',
      },
      {
        id: 8,
        title: 'Bowling Stars',
        image: BowlingStars,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/BkdJhTX50B',
      },
      {
        id: 9,
        title: 'City Cricket',
        image: CityCricket,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/HJP4afkvqJQ',
      },
      {
        id: 10,
        title: 'Bubble-Wipeout',
        image: BubbleWipeout,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/H1AN6fkwqJ7',
      },
      {
        id: 11,
        title: 'Escape Run',
        image: EscapeRun,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/Skz4pzkDqyX',
      },
      {
        id: 12,
        title: 'Merge Mania',
        image: MergeMania,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/hfPOimYqY',
      },
  ];
  
  export default games;