import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      dark: '#000000',
      main: '#1A4D2E',
      main600: '#050f09',
      main500: '#0a1f12',
      main400: '#153e25',
      main300: '#1a4d2e',
      main200: '#769482',
      main100: '#a3b8ab',
      main50: '#d1dbd5',
    },
    secondary: {
      main: '#EDB426', 
      secondary: '#FF9F29'
    },
    success: {
        main: '#C8E4B2',
    }
  },
  typography: {
    fontFamily: 'Mukta, sans-serif', 
    color: "white",
    h3: {
      fontSize: '1.5rem',
    '@media (min-width:600px)': {
      fontSize: '3rem',
    },
    },
    h4: {
      fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '2rem',
    },
  }
  },
  spacing: [0, 4, 8, 16, 32, 64],
});

export default theme;
