import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


const DisclaimerSection = () => {
  return (
    <div className="disclaimer">
      <Container>
      <Typography variant="h4">Disclaimer</Typography>
      <Typography variant="body1" paragraph>
        Welcome to our social pokies website. Before using this site and/or any of the games
        (collectively, the "Services") provided by us, please read and agree to the terms and
        conditions set forth below:
      </Typography>
      <Typography variant="h6">General Information:</Typography>
      <Typography>
        We are not a gambling operator and the Services are not intended to be used for real-money gambling purposes.
      </Typography>
      <Typography>
        The Services are virtual pokies-style entertainment games and do not involve any real money bets or prizes.
      </Typography>
      <Typography>
        We do not collect any personal information from you except for what is necessary to provide you with the Services. Any personal information you provide to us will be treated in accordance with our privacy policy.
      </Typography>
      <Typography variant="h6">Responsibility for your use of the Services:</Typography>
      <Typography>
        You agree not to use the Services for any illegal or fraudulent purpose or to interfere with the use of the Services by others.
      </Typography>
      <Typography>
        You acknowledge that any virtual currency that we provide to you as part of the Services has no real-world value and cannot be redeemed for cash or other prizes.
      </Typography>
      <Typography variant="h6">Acceptance of these terms and conditions:</Typography>
      <Typography>
        By using the Services, you signify your agreement to these terms and conditions. If you do not agree to these terms and conditions, you may not use the Services.
      </Typography>
      </Container>
    </div>
  );
};

export default DisclaimerSection;
