import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Form from './Form';
import Header from './Header';
import {contactUs} from "../assets";
import DisclaimerSection from './DisclaimerSection';

export default function Contacts() {
  return (
    <div>
      <Header/>
    <Container maxWidth="lg" id="contacts">
      <Typography variant="h4" textAlign="center" gutterBottom>Have Any Question?</Typography>
      <Grid container justifyContent="center" textAlign="center" alignItems="center" marginTop="2rem">
    
        <Grid item xs={12} sm={6}>
          <Form/>
        </Grid>
        <Grid item xs={12} sm={6} mx="auto">
          <img src={contactUs} alt="" style={{ maxWidth: '600px', width: "100%", height: 'auto' }}/>
        </Grid>
</Grid>
    </Container>
<DisclaimerSection/>
    </div>
  )
}
